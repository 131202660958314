import { useState } from "react";
import p1 from "../assets/img/sections/1_1.jpeg";
import { useStoreState } from "easy-peasy";

export default function MuseoPage() {
  const text = useStoreState((state) => state.text);



  return (
    <div className="bg-white px-6 py-8 pb-0 lg:px-8">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:mb-6">
        {/* <p className="text-base font-semibold leading-7 text-indigo-600">
          Introducing
        </p> */}
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {text.MuseoPage.title}
        </h1>
        <p className="mt-6 text-xl font-medium leading-8">
          {text.MuseoPage.subtitle}
        </p>
        <figure className="mt-8">
          <img
            className="rounded-xl h-96 w-full bg-gray-50 object-cover"
            src={p1}
            alt=""
          />
        </figure>
        <div className="mt-16 max-w-4xl">
          <p className="mt-6 text-xl font-medium leading-8">
            {text.MuseoPage.text1}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.MuseoPage.text2}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.MuseoPage.text3}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.MuseoPage.text4}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.MuseoPage.text5}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.MuseoPage.text6}
          </p>
        </div>
      </div>
    </div>
  );
}
