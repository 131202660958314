import Hero from "../components/Hero";
import Header from "../components/Header";
import Cta from "../components/Cta";
import ImageGrid from "../components/ImageGrid";
import Section1 from "../components/Section1";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import VirtualTour from "../components/VirtualTour";

import React from "react";
import VirtualTourText from "../components/VirtualTourText";

export default function Home() {
  return (
    <div>
      <Hero />
      <Header />
      <Section3 />
      <Section1 />
      <Section2 />
      {/* <ImageGrid /> */}
      <VirtualTour />
      <VirtualTourText />
      <Cta />
    </div>
  );
}
