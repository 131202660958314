import p1 from "../assets/img/sections/2_1.jpeg";
import { useStoreState } from "easy-peasy";

export default function Example() {
  const text = useStoreState((state) => state.text);
  return (
    <div className="bg-white px-6 py-8 pb-0 lg:px-8">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:mb-6">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {text.SezioniPage.title}
        </h1>
        <figure className="mt-8">
          <img
            className="rounded-xl h-96 w-full bg-gray-50 object-cover"
            src={p1}
            alt=""
          />
        </figure>
        <div className="mt-16 max-w-4xl">
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            {text.SezioniPage.section1.title}
          </h2>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.SezioniPage.section1.text1}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.SezioniPage.section1.text2}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.SezioniPage.section1.text3}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.SezioniPage.section1.text4}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.SezioniPage.section1.text5}
          </p>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            {text.SezioniPage.section2.title}
          </h2>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.SezioniPage.section2.text1}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.SezioniPage.section2.text2}
          </p>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            {text.SezioniPage.section3.title}
          </h2>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.SezioniPage.section3.text1}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.SezioniPage.section3.text2}
          </p>
        </div>
      </div>
    </div>
  );
}
