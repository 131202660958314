import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

//pages
import HomePage from "./pages/HomePage";
import MuseoPage from "./pages/MuseoPage";
import SezioniPage from "./pages/SezioniPage";
import NewsPage from "./pages/NewsPage";
import VirtualTourPage from "./pages/VirtualTourPage";
import InfoPage from "./pages/InfoPage";
import { StoreProvider, createStore, action } from "easy-peasy";

//LANG
import en from "./languages/en.json";
import it from "./languages/it.json";

const store = createStore({
  language: localStorage.getItem("language") || "it",
  text: localStorage.getItem("language") === "it" ? it : en,
  changeLanguage: action((state, payload) => {
    if (state.language === "it") {
      state.language = "en";
      state.text = en;
    } else if (state.language === "en") {
      state.language = "it";
      state.text = it;
    }
  }),
  changeLanguageIt: action((state, payload) => {
    state.language = "it";
    state.text = it;
  }),
  changeLanguageEn: action((state, payload) => {
    state.language = "en";
    state.text = en;
  }),
});

function App() {
  return (
    <div className="App">
      <StoreProvider store={store}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/museo" element={<MuseoPage />}></Route>
            <Route path="/sezioni" element={<SezioniPage />}></Route>
            <Route path="/virtual-tour" element={<VirtualTourPage />}></Route>
            <Route path="/info" element={<InfoPage />}></Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </StoreProvider>
    </div>
  );
}

export default App;
