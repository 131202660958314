import { useStoreState } from "easy-peasy";
import VirtualTour from "../components/VirtualTour";
import VirtualTourText2 from "../components/VirtualTourText2";

export default function Example() {
  const text = useStoreState((state) => state.text);
  return (
    <div className="bg-white  py-8 pb-0 ">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:mb-6">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {text.VirtualTourPage.title}
        </h1>
        <p className="mt-6 text-xl font-medium leading-8">
          {/* {text.VirtualTourPage.subtitle} */}
        </p>
        </div>
        {/* <figure className="mt-8">
          <iframe
            className="rounded-xl h-96 w-full bg-gray-50 object-cover"
            height="550"
            width="100%"
            src="https://my.matterport.com/show/?m=4Fnz38njPfJ"
            frameborder="0"
            allowfullscreen
            allow="xr-spatial-tracking"
          ></iframe>
        </figure> */}
       
           <VirtualTour />
           <VirtualTourText2 />
       
       
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:mb-6">
        <div className="mt-16 max-w-4xl">
          <p className="mt-6 text-xl font-medium leading-8">
            {text.VirtualTourPage.text1}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.VirtualTourPage.text2}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.VirtualTourPage.text3}
          </p>
          <p className="mt-6 text-xl font-medium leading-8">
            {text.VirtualTourPage.text4}
          </p>
        </div>
      </div>
    </div>
  );
}
