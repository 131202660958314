import React from "react";
import VirtualTourText from "./VirtualTourText";

export default function VirtualTour() {
  return (
    <div>
      <iframe
        height="550"
        width="100%"
        src="https://my.matterport.com/show/?m=4Fnz38njPfJ"
        frameborder="0"
        allowfullscreen
        allow="xr-spatial-tracking"
      ></iframe>
     
    </div>
  );
}
