import p1 from "../assets/img/sections/2_1.jpeg";
import p2 from "../assets/img/sections/2_2.jpeg";
import p3 from "../assets/img/sections/2_3.jpeg";
import p4 from "../assets/img/sections/2_4.jpeg";
import { useStoreState } from "easy-peasy";

const people = [
  {
    imageUrl: p1,
  },
  {
    imageUrl: p2,
  },
  {
    imageUrl: p3,
  },
  // {
  //   imageUrl: p4,
  // },
];

export default function Section2() {
  const text = useStoreState((state) => state.text);
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:mb-4">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {text.Section2.title}
            </h2>
            <p className="text-xl text-gray-500">
              {text.Section2.text}
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul
              role="list"
              className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-6 sm:space-y-0 lg:gap-x-8"
            >
              {people.map((person) => (
                <li key={person.imageUrl}>
                  <div className="space-y-4">
                    <div className="aspect-w-3 aspect-h-2">
                      <img
                        className="object-cover shadow-lg rounded-lg"
                        src={person.imageUrl}
                        alt=""
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
