import { useStoreState } from "easy-peasy";

export default function Cta() {
  const text = useStoreState((state) => state.text);
  return (
    <div className="bg-gray-200">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-neutral-900 sm:text-4xl">
          <span className="block">{text.Cta.title}</span>
          <span className="block">{text.Cta.subtitle}</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-400">
          {text.Cta.text}
        </p>
        <a
          href="https://www.montecreto.eu"
          target="blank"
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-red-600 bg-white hover:bg-red-600 hover:text-white sm:w-auto"
        >
          {text.Cta.button}
        </a>
      </div>
    </div>
  );
}
